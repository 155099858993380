<template>
  <div>
    <container>

      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>
       <div slot="bg_map"></div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/IndexTable.vue')
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)

    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
  }
}
</script>
